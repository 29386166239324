import React from "react";
import Helmet from '../../compnents/helmet';
import SalesImageBanner from "../../assets/images/sales.jpg";
import { useParams } from 'react-router-dom';

export default function Sales() {

    const { target } = useParams();

    const selectList = [
        {
            name: "Digital Marketing Services",
            value: "digital"
        },
        {
            name: "Website and Mobile App Creation",
            value: "webiste"
        },
        {
            name: "SMS Services-Value added Services",
            value: "sms"
        },
        {
            name: "Software Solutions",
            value: "software"
        }

    ]
    return (
        <div className="container">
            <div className="mt-5 text-center sorry">
                <h1>Page under construction </h1>

            </div>
        </div>
        // <article id="sales-wapper">
        //     <Helmet title="Sales"/>
        //     <section className="sales-banner">
        //         <img src={SalesImageBanner} alt="sales banner image" className="sales-img d-none d-md-block"/>
        //         <img src={SalesImageBanner} alt="sales banner image" className="sales-img d-block d-md-none"/>
        //         <div className="content-banner">
        //             <p className="banner-text-header">We’re here to help!</p>
        //             <p className="banner-text-description">Tell us more about your project and a growth specialist will
        //                 contact you shortly to discuss</p>
        //         </div>
        //     </section>
        //     <section id="sales-body" className="container">
        //         <div className="row">
        //             <div className="col-md-6 col-12 after-request">
        //                 <p className="request-quastion">What Happens After I Send My Request ?</p>
        //                 <div className="d-flex q-1">
        //                     <p className="q-number">01</p>
        //                     <p className="q-answer"/>
        //                 </div>
        //                 <div className="d-flex q-2 ">
        //                     <p className="q-number">02</p>
        //                     <p className="q-answer"/>
        //                 </div>
        //                 <div className="d-flex q-3">
        //                     <p className="q-number">03</p>
        //                     <p className="q-answer"/>
        //                 </div>
        //             </div>
        //             <div className="col-md-4 col-12">
        //                 <form action="#" className="form">
        //                     <input type="text" placeholder={`Full Name *`} className="f-name-input input-common"/>
        //                     <input type="text" placeholder="Email *" className="email-input input-common"/>
        //                     <input type="text" placeholder="Mobile / Phone" className="mobile-input input-common"/>
        //                     <select className="select-input input-common" value={target}>
        //                         {selectList.map((item, index) => (
        //                             <option key={index} value={item.value}>{item.name}</option>
        //                         ))}
        //                     </select>
        //                     <textarea className="text-area-input input-common" rows="3"
        //                               placeholder="Message"/>
        //                     <div className="btn-contact">
        //                         <input type="submit" value="Send" className="form-submit-input"/>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </section>

        // </article>
    );
}