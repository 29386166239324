import React from 'react';
import Helmet from '../../compnents/helmet';
import Banner from "../../assets/images/MediaCenter.jpg"
import BannerMobile from "../../assets/images/mediaCenterMobile.jpg";
import NewsComponent from './NewsComponent';
export default function MediaCenter() {
    return (

        <div className="container">
            <div className="mt-5 text-center sorry">
                <h1>Page under construction </h1>

            </div>

        </div>
        // <article id="meadia-center-wrapper">
        //     <Helmet title="Meadia Center" />
        //     <section className="meadia-center-banner">
        //         <img src={Banner} alt="" className="meadia-center-banner-img d-none d-md-block" />
        //         <img src={BannerMobile} alt="" className="meadia-center-banner-img d-block d-md-none" />
        //
        //         <div className="banner-content">
        //             <p className="banner-content-header">Media Center</p>
        //             <p className="banner-content-description">Is simply dummy text of the printing and typesetting </p>
        //         </div>
        //     </section>
        //     <section className="container-fluid">
        //         <section className="news-wrapper container">
        //             <NewsComponent
        //                 date="20 SEP 2021"
        //                 title="is simply dummy text of the printing"
        //                 description="is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since, is simply dummy text of the printing and typesetting industry. Lorem the printing and typesetting "
        //             />
        //             <NewsComponent
        //                 date="20 SEP 2021"
        //                 title="is simply dummy text of the printing"
        //                 description="is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since, is simply dummy text of the printing and typesetting industry. Lorem the printing and typesetting "
        //             />
        //             <NewsComponent
        //                 date="20 SEP 2021"
        //                 title="is simply dummy text of the printing"
        //                 description="is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since, is simply dummy text of the printing and typesetting industry. Lorem the printing and typesetting "
        //             />
        //             <NewsComponent
        //                 date="20 SEP 2021"
        //                 title="is simply dummy text of the printing"
        //                 description="is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since, is simply dummy text of the printing and typesetting industry. Lorem the printing and typesetting "
        //             />
        //         </section>
        //     </section>
        // </article>
    );
}