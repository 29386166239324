import React from 'react';
import Helmet from '../../compnents/helmet';
import ContactImage from "../../assets/images/contact.jpg";
import ContactImageMobile from "../../assets/images/contact-mobile.jpg";
import FacebookIcon from "../../assets/images/social-media-contact/facebook.png";
import InstagramIcon from "../../assets/images/social-media-contact/instagram.png";
import LinkdinIcon from "../../assets/images/social-media-contact/linkedIn.png";
import TwitterIcon from "../../assets/images/social-media-contact/twitter.png";
import Email from "../../assets/images/social-media-contact/email.png";
import Phone from "../../assets/images/social-media-contact/phone.png";
import Location from "../../assets/images/social-media-contact/location.png";
import CustomMap from '../../compnents/map';

export default function Contact() {


    return (
        <article id="contact-page-wrapper">
            <Helmet title="Contact Us"/>
            <section className="contact-us-banner ">
                <img src={ContactImage} alt="contact" className="contact-us-img d-none d-md-block"/>
                <img src={ContactImageMobile} alt="contact" className="contact-us-img d-block d-md-none"/>
                <div className="banner-content">
                    <p className="contact-us-text">Contact Us</p>
                    <p className="contact-us-description">Drop us a line. We would love to hear from you.</p>
                </div>
            </section>
            <section id="contact-us-body" className="container">
                <div className="row">
                    <div className="col-md-6 mobile">
                        <div className="social-media-block d-flex flex-column">
                            <div className="mobile-view-1">
                                <a href='https://www.instagram.com/kastana.jo/'>
                                    <img src={InstagramIcon} alt="instagram  icon" className="social-media-icon"/>
                                </a>
                                <a href='https://web.facebook.com/kastanajo'>
                                    <img src={FacebookIcon} alt="Facebook icon" className="social-media-icon"/>
                                </a>
                                <a href='https://www.linkedin.com/company/kastana-for-information-technology'>
                                    <img src={LinkdinIcon} alt="linkedIn icon" className="social-media-icon"/>
                                </a>
                                <a href='https://twitter.com/kastanajo'>
                                    <img src={TwitterIcon} alt="Twitter icon" className="social-media-icon last-icon"/>
                                </a>

                            </div>
                            <div className="mobile-view-2">
                                <div className="d-flex align-items-center icon-common">
                                    <img src={Email} alt="Email icon" className="social-media-icon kastana "/>
                                    {/* <p className="text-desc m-0">info @kastana.net</p> */}
                                    <a href="mailto:info@kastana.net?subject=Mail from Our Site"
                                       className="text-desc mailto">info @kastana.net</a>

                                </div>
                                <div className="d-flex align-items-center icon-common">
                                    <img src={Location} alt="Location icon" className="social-media-icon "/>
                                    <p className="text-desc location m-0">
                                        Amman-Jordan, Prince Rashed suburb,
                                        AL-Mahara street, building # 16, 3rd floor</p>

                                </div>
                                <div className="d-flex align-items-center icon-common">
                                    <img src={Phone} alt="Phone icon" className="social-media-icon"/>
                                    {/* <p className="text-desc m-0">+962 6 5527012</p> */}
                                    <div className='d-flex flex-column'>

                                        <a href="tel:+962780888912"
                                           className="text-desc m-0 mailto mb-2">+962780888912</a>
                                        <a href="tel:+962 6 5527012" className="text-desc m-0 mailto">+962 6 5527012</a>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <form action="#" className="form">
                            <input type="text" placeholder="Full Name" className="f-name-input input-common"/>
                            <input type="text" placeholder="Email" className="email-input input-common"/>
                            <select className="select-input input-common">
                                <option>Sales</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                            <textarea className="text-area-input input-common" rows="3"
                                      placeholder="Message"></textarea>
                            <div className="btn-contact">
                                <input type="submit" value="Send" className="form-submit-input"/>
                            </div>
                        </form>
                    </div>
                </div>

            </section>
            <section id="google-map">
                <iframe className="gmap_canvas"
                        src="https://maps.google.com/maps?q=31.9750396,35.8497617&z=17&draggable=false&output=embed"
                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>

            </section>

        </article>
    );
}