import React from 'react';
import { Link } from 'react-router-dom';
import Arrow from "./assets/images/footer-arrow.png";
import FooterLogo from "./assets/images/footer-logo.png";
import FacebookIcon from "./assets/images/social-media/facebook.png";
import InstagramIcon from "./assets/images/social-media/instagram.png";
import LinkdinIcon from "./assets/images/social-media/linkdin.png";
import TwitterIcon from "./assets/images/social-media/twitter.png";

export default function Footer() {

    return (
        <footer id="footer">
            <div className="container-fluid">
                <div className="d-flex justify-content-center flex-wrap">
                    <div className="footer-newsletter col-lg-3 col-12">
                        <label className="footer-item-header">Join Our Newsletter</label>
                        <p className="footer-newsletter-body">Sign up to get our weekly article of advice and
                            insights </p>
                        <div className="input-with-arrow-block d-flex">
                            <input type="text" placeholder="Add your Email" className="footer-input" />
                            <div className="arrow-block d-flex justify-content-center">
                                <img src={Arrow} alt="arrow" className="footer-arrow" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 d-none d-md-block">
                        <label className="footer-item-header">
                            Kastana
                        </label>
                        <ul className="footer-list">
                            <li className="footer-list-item">
                                <Link className="footer-link" to="/about">About US</Link>
                            </li>
                            <li className="footer-list-item">
                                <Link className="footer-link" to="/media-center">Media Center</Link>
                            </li>
                            <li className="footer-list-item">
                                <Link className="footer-link" to="/contact">Contact us</Link>
                            </li>
                            <li className="footer-list-item">
                                <Link className="footer-link" to="/services">Services</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 d-none d-md-block">
                        <label className="footer-item-header">
                            Services
                        </label>
                        <ul className="footer-list">
                            <li className="footer-list-item">
                                <Link className="footer-link" to="/sales/about">Website and Mobile App Creation</Link>
                            </li>
                            <li className="footer-list-item">
                                <Link className="footer-link" to="/sales/sms">SMS Services-Value added Services </Link>
                            </li>
                            <li className="footer-list-item">
                                <Link className="footer-link" to="/sales/software">Software Solutions</Link>
                            </li>
                            <li className="footer-list-item">
                                <Link className="footer-link" to="/sales/digital">Digital Marketing</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-12">
                        <img src={FooterLogo} alt="Footer logo" className="footer-logo d-none d-md-block" />
                        <div className="social-media-block mt-lg-3 mt-4">
                            <a href='https://www.instagram.com/kastana.jo/'>
                                <img src={InstagramIcon} alt="Instagram icon" className="social-media-icon" />
                            </a>
                            <a href='https://web.facebook.com/kastanajo'>
                                <img src={FacebookIcon} alt="Facebook icon" className="social-media-icon" />
                            </a>
                            <a href='https://www.linkedin.com/company/kastana-for-information-technology/'>
                                <img src={LinkdinIcon} alt="LinkedIn icon" className="social-media-icon" />
                            </a>
                            <a href='https://twitter.com/kastanajo'>
                                <img src={TwitterIcon} alt="Twitter icon" className="social-media-icon last-icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}