import React from "react";
import { Link } from "react-router-dom";
import cls from "../../util/cls"
export default function CardComponent({
    title,
    description,
    linkText,
    link,
    className
}) {
    return (
        <div className={cls("our-services-card", className)}>
            <label className="our-services-card-title">{title}</label>
            <p className="our-services-card-description">{description}</p>
            <Link to={link} className="our-services-card-link">{linkText}</Link>
        </div>
    );
}