import React from 'react';
import ServicesComponent from "./ServicesComponent";
import DigitalMarketingImage from "../../assets/images/digitalMarketing.jpg"
import Helmet from '../../compnents/helmet';
import { Link } from 'react-router-dom';

export default function Services() {
    return (
        <section id="services-wrapper">

            <div className="container">
                <Helmet title="Services" />

                <h4 className="services-header mt-8">Digital Marketing</h4>
                <div className="row">

                    <div className="col-md-6 col-12 desc">
                        <p className="services-description">As experts in the field, we help corporates and individuals create a unique footprint in the digital world. We offer our clients the ability to: increase brand awareness and loyalty, gain insights, build strong relationships with customers, increase website traffic and search ranking,  and find out what competitors are doing.</p>
                        <Link to="/sales/digital" className="services-btn" >SPEAK TO OUR SPECIALIST</Link>
                    </div>
                    <div className="col-md-6 col-12 image">
                        <img src={DigitalMarketingImage} alt="Digital Marketing Image" className="services-image" />
                    </div>
                </div>
                <div className="our-offer-wrapper">
                    {/* <div className="our-offer"> */}
                    <p className="our-offer-header">What do we offer?</p>
                    {/* </div> */}
                    <div className="row">
                        <ServicesComponent
                            title="Social media management"
                            description="We analyze social media audiences, tailor and distribute content across social media profiles, monitor online conversations, collaborate with influencers, and monitor, measure, and report on social media performance."
                        />
                        <ServicesComponent
                            title="Social media strategy development "
                            description="We help businesses identify the right social media platform to:  communicate with the targeted customers, set social media key performance indicators, structure social media team, evaluate the right social media tools for the business, and set a social media budget. "
                        />
                        <ServicesComponent
                            title="Social Media Consultation services"
                            description="Our social media consultants help businesses improve their presence on social media, create engaging organic posts that drive likes and followers, and nurture those followers into customers."
                        />
                        <ServicesComponent
                            title="Campaigns and Competitions."
                            description="Competitions and campaigns help in increasing brand awareness and excitement around it. Our creative team offers design, promotion, follow-up, and support to reach the desired sales objectives."
                        />
                        <ServicesComponent
                            title="Social media advertising "
                            description="We offer social media advertising packages that suit everyone at competitive prices."
                        />

                    </div>
                </div>
            </div>
        </section>
    );
}