import React from "react";
// import cls from "../../util/cls";
import Mask from "../../assets/images/mask.png";
export default function ServicesComponent({
    title,
    description,

}) {
    return (
        <div className="col-md-6 col-12">
            <div className="d-flex">
                <div className="col-1 p-0">
                    <img src={Mask} className="mask-image" />
                </div>
                <div className="col-md-11 col-10">
                    <label className="our-offer-box-header d-inline">
                        {title}
                    </label>
                    <p className="our-offer-box-description">{description}</p>
                </div>
            </div>
        </div>
    );
}