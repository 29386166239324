import React from 'react';
import Header from './header';
import Main from './main';
import { BrowserRouter as Router } from "react-router-dom";
import Footer from './footer';

export default function App() {
  return (
    <Router>
      <Header />
      <Main />
      <Footer />
    </Router>
  )
}