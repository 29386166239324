import React, { useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import Logo from "./assets/images/logo.png";
import FaceBook from "./assets/images/social-media/facebook.png";
import Instagram from "./assets/images/social-media/instagram.png";
import LinkedIn from "./assets/images/social-media/linkdin.png";
import Twitter from "./assets/images/social-media/twitter.png";
import BurgerMenu from "./assets/images/BurgerMenu.png";
import Close from "./assets/images/colse.png";
import cls from "./util/cls";
import { Collapse } from 'react-bootstrap';


/**
 *
 */
export default withRouter(function Header({ location, history }) {
    const [currentPath, setCurrentPath] = useState('/home');
    const [toggelNavBar, setToggelNavBar] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setCurrentPath(location.pathname)
    }, [location])

    const LINKS = [
        {
            name: "Home",
            path: '/home'
        },
        {
            name: "About",
            path: '/about'
        },
        // {
        //     name: "Services",
        //     path: '/services',
        //     dropDown: [
        //         {
        //             name: 'Website and Mobile App Creation',
        //             path: `/sales/webiste`
        //         },
        //         {
        //             name: 'SMS Services-Value added Services',
        //             path: `/sales/sms`

        //         },
        //         {
        //             name: ' Software Solutions',
        //             path: `/sales/software`
        //         },
        //         {
        //             name: ' Digital Marketing',
        //             path: `/sales/digital`
        //         }
        //     ]
        // },
        {
            name: 'Media Center',
            path: '/media-center'
        }
        ,
        {
            name: 'Contact',
            path: '/contact'
        }
    ]
    const handelNav = () => {
        setToggelNavBar((pre) => !pre);
    }

    return (
        <header id="header" className="container">

            {/** Desktop nav */}
            <section className="d-none d-md-flex justify-content-between">
                <Link to="/home"><img src={Logo} alt="logo" className="logo" /></Link>
                <nav className="nav">
                    {LINKS.map((item, index) => (
                        <li key={index}
                            className={cls("nav-item", item.path === currentPath ? 'active' : '', item.name === 'Services' ? "services" : '')}>
                            <Link to={item.path} className="nav-link">
                                {item.name} {item.name === "Services" ? (
                                    <div className="triangle"></div>

                                ) : []}
                            </Link>

                            <div className="drop-down">
                                {item.dropDown ? item.dropDown.map((item, index) => (
                                    <Link to={item.path} key={index} className="drop-down-link">{item.name}</Link>)) : []}
                            </div>
                        </li>
                    ))}
                </nav>

            </section>
            <section className="d-md-none d-flex justify-content-between ">
                <Link to="/home"><img src={Logo} alt="logo" className="logo" /></Link>
                <img src={BurgerMenu} onClick={handelNav} alt="burger menu" className="burger-menu" />
            </section>
            <section id="nav-mobile-view" className={toggelNavBar ? "d-flex d-md-none flex-column" : 'd-none'}>
                <div className="d-flex justify-content-end nav-mobile">
                    <img src={Close} onClick={handelNav} alt="close btn" className="close-btn" />
                </div>
                <div className="nav-item">
                    {LINKS.map((item, index) => (
                        <li key={index} className={cls(item.path === currentPath ? `active mt-4` : `mt-4`)}>
                            {item.name === 'Services' ? (
                                <a href='#'
                                    className="nav-link"
                                    aria-expanded={open}
                                    onClick={(e) => setOpen((pre) => !pre)}
                                >
                                    {item.name}
                                    <div className="triangle" />
                                </a>
                            ) : (
                                <Link to={item.path}
                                    className="nav-link"
                                    aria-expanded={open}
                                    onClick={() => {
                                        setToggelNavBar((pre) => !pre);

                                    }}
                                >
                                    {item.name}
                                </Link>
                            )}
                            {item.name === "Services" ? (
                                <Collapse in={open}>

                                    <div className="drop-down">
                                        {item.dropDown ? item.dropDown.map((item, index) => (
                                            <p className="nav-link" onClick={() => {
                                                history.push(item.path)
                                                setToggelNavBar((pre) => !pre);
                                                setOpen((pre) => !pre)
                                            }} key={index}>
                                                {item.name}

                                            </p>)) : []}
                                    </div>
                                </Collapse>

                            ) : []}
                        </li>
                    ))}

                </div>
                <div className="d-flex social-meadia m-auto">
                    <li>
                        <a href="https://www.instagram.com/kastana.jo/">
                            <img src={Instagram} alt="instagram logo" className="social-meadia-icon instagram" />
                        </a>
                    </li>
                    <li>
                        <a href="https://web.facebook.com/kastanajo">
                            <img src={FaceBook} alt="Facebook logo" className="social-meadia-icon facebook" />

                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/kastana-for-information-technology/">
                            <img src={LinkedIn} alt="linkedIn logo" className="social-meadia-icon linkedin" />

                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/kastanajo">
                            <img src={Twitter} alt="twitter logo" className="social-meadia-icon twitter" />

                        </a>
                    </li>
                </div>
            </section>
        </header>
    )

})