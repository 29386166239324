import React from 'react';
import Helmet from '../../compnents/helmet';
import OurStoryImage from "../../assets/images/About-our-story.png"
import OurStoryImageMobile from "../../assets/images/About-our-story-mobile.png"
import ExpiranceImage from "../../assets/images/expiriance.png"
import ExpiranceImageMobile from "../../assets/images/20years.png"
import OurPartnerImage from "../../assets/images/our-partner.png"
import OurPartnerImageMobile from "../../assets/images/about-mobile.png"
import OurMissionImage from "../../assets/images/our-mission.png"
import OurMissionImageMobile from "../../assets/images/our-mis-mob.png"

export default function About() {
    return (
        <article id="about-page-wrapper">
            <Helmet title="About Us" />
            <div className="our-story container">
                <div className="row ">
                    <div className="our-story-wrapper col-md-6 d-block d-md-none">
                        <h1 className="our-story-header">Our Story</h1>
                        <img src={OurStoryImageMobile} alt="our story" className="our-story-img" />
                    </div>
                    <div className=" col-md-6 col-12 d-block d-md-none">
                        <p className="our-story-description ">From the heart of the Jordanian capital of Amman and with nearly a 20 years of expirance, we, a group of young professionals, started Kastana to offer individuals and businesses high-end IT services that suit’s their needs at the best prices.</p>
                    </div>
                </div>
            </div>
            <div className="our-story container d-none d-md-block">
                <div className="row ">
                    <div className="our-story-wrapper col-md-6">
                        <h1 className="our-story-header">Our Story</h1>
                        <p className="our-story-description ">From the heart of the Jordanian capital of Amman and with nearly a 20 years of expirance, we, a group of young professionals, started Kastana to offer individuals and businesses high-end IT services that suit’s their needs at the best prices.</p>
                    </div>
                    <div className=" col-md-6 col-10 d-none d-md-block">
                        <img src={OurStoryImage} alt="our story" className="our-story-img" />
                    </div>
                </div>
            </div>
            {/* Lets Start our fatwa */}

            <div className="expiriance-wrapper d-none d-md-block">
                <div className="expiriance container">
                    <div className="row">
                        <div className="col-md-8 col-10">
                            <img src={ExpiranceImage} alt="our story" className="expiriance-img" />
                        </div>
                        <div className="col-md-4 col-10">
                            <h1 className="expiriance-header">20 Years of Expirance</h1>
                            <p className="expiriance-description ">In 2007, we were a startup specializing in value-added services, and mobile applications.
                                Today, Kastana is the mother of 7 companies that provide a comprehensive set of services like Software solutions, Websites and apps, E-marketing, and much more.
                                <label className="expiriance-description-label">The road was bumpy and the challenge to excel in a fast-growing market was huge.
                                    Still, with great determination and hard work, we have created a unique position in the IT industry in Jordan and the MENA region.</label>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add secound Dev */}
            <div className="expiriance-wrapper d-block d-md-none">
                <div className="expiriance container">
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <h1 className="expiriance-header">20 Years of Expirance</h1>
                            <img src={ExpiranceImageMobile} alt="our story" className="expiriance-img" />
                        </div>
                        <div className="col-md-4 col-12">
                            <p className="expiriance-description ">In 2007, we were a startup specializing in value-added services, and mobile applications.
                                Today, Kastana is the mother of 7 companies that provide a comprehensive set of services like Software solutions, Websites and apps, E-marketing, and much more.
                                <label className="expiriance-description-label">The road was bumpy and the challenge to excel in a fast-growing market was huge.
                                    Still, with great determination and hard work, we have created a unique position in the IT industry in Jordan and the MENA region.</label>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Lets End It */}
            {/* Lets Start our fatwa */}

            <div className="our-clients container d-none d-md-block">
                <div className="row">
                    <div className="col-md-6 col-10  ">
                        <h1 className="our-clients-header">Our Clients Are Our Partners</h1>
                        <p className="our-clients-description ">
                            ,We yearn for more development and expansion. worked with clients through our located offices and an amazing employees in Saudi Arabia, Jordan, Palestine, Morocco, Iraq, Yemen, and Sudan, and we're looking to expand internationally.
                            <label className="our-clients-description-label">All of us at Kastana believe that our clients are our partners, and their success is ours. So, we dedicate our time, effort, and resources to surpass expectations. </label>
                        </p>
                    </div>
                    <div className="col-md-6 col-10">
                        <img src={OurPartnerImage} alt="our client" className="our-clients-img" />
                    </div>
                </div>
            </div>
            {/* Add secound Dev */}
            <div className="our-clients container d-block d-md-none">
                <div className="row">
                    <div className="col-md-6 col-12  ">
                        <h1 className="our-clients-header">Our Clients Are Our Partners</h1>
                        <img src={OurPartnerImageMobile} alt="our client" className="our-clients-img" />
                    </div>
                    <div className="col-md-6 col-11">
                        <p className="our-clients-description ">
                            ,We yearn for more development and expansion. worked with clients through our located offices and an amazing employees in Saudi Arabia, Jordan, Palestine, Morocco, Iraq, Yemen, and Sudan, and we're looking to expand internationally.
                            <label className="our-clients-description-label">All of us at Kastana believe that our clients are our partners, and their success is ours. So, we dedicate our time, effort, and resources to surpass expectations. </label>
                        </p>
                    </div>
                </div>
            </div>
            {/* Lets End It */}

            <div className="our-mission container">
                <div className="row">

                    <div className="col-md-8 col-10">
                        <img src={OurMissionImage} alt="our client" className="our-mission-img d-none d-md-block" />
                        <img src={OurMissionImageMobile} alt="our client" className="our-mission-img d-block d-md-none" />

                    </div>
                    <div className="col-md-4 col-11">
                        <p className="our-mission-header">Our Mission</p>
                        <p className="our-mission-description">
                            We aim to create a position where our reputation speaks for us in the Telecommunications and IT industry.
                        </p>
                        <p className="our-vision-header">Our Vision</p>
                        <p className="our-vision-description">
                            We seek to deliver qualitative solutions to serve the needs of our clients.
                        </p>
                    </div>
                </div>
            </div>


        </article>
    );
}