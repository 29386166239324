import React from 'react';
import Banner from "../../assets/images/homePageBanner.png";
import CardComponent from './cardComponent';
import Helmet from '../../compnents/helmet';
import OrangeLogo from "../../assets/images/companyLogo/orange-logo.png"
import ZainLogo from "../../assets/images/companyLogo/zain.png"
import UmniahLogo from "../../assets/images/companyLogo/umniah.png"
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';


export default function Home() {
    const images = [
        { url: 'images/companyLogo/orange-logo.png' },
        { url: 'images/companyLogo/zain.png' },
        { url: 'images/companyLogo/umniah.png' },
    ];
    return (
        <article id="home-page-warpper">
            <Helmet title="Home Page" />
            <section className="banner-section">
                <img src={Banner} alt="home page banner" className="banner-image d-none d-md-block" />
                <img src={Banner} alt="home page banner" className="banner-image d-block d-md-none" />
                <div className="banner-content">
                    <p className="lets-create-text">LET’S CREATE YOUR SUCCESS TOGETHER</p>
                    <p className="kastana-text">KASTANA… Your Gate to Digital Solutions   </p>
                    <Link to="/sales/webiste" className="get-started-btn">GET STARTED</Link>
                </div>
            </section>
            <section id="home-page-content" className="container">
                <p className="what-we">What We Do</p>
                <div className="row">
                    <CardComponent
                        title="Website and Mobile App Creation"
                        description=" We employ our expertise in design and development, the latest tools and techniques, and user experience best practices to offer our clients easy, creative, modern and easy-to-use IOS & Android mobile applications and websites."
                        linkText="I need this"
                        link="/sales/webiste"
                        className="col-md-6 col-12"
                    />
                    <CardComponent
                        title="SMS Services-Value added Services"
                        description=" On a conceptual level, value-added services in the telecommunications sector provide value to the regular service offering, encouraging customers to use their phones more and allowing the operator to increase their average income per user"
                        link="/sales/sms"
                        linkText="I need this"
                        className="col-md-6 col-12"
                    />
                    <CardComponent
                        title="Software Solutions"
                        description="As the preferred software house in the MENA region, we produce and develop end-to-end solutions to help businesses run and manage their operations efficiently to save time and effort."
                        linkText="I need this"
                        link="/sales/software"
                        className="col-md-6 col-12"
                    />
                    <CardComponent
                        title="Digital Marketing"
                        description="As experts in the field, we help corporates and individuals create a unique footprint in the digital world. We offer our clients the ability to: increase brand awareness and loyalty, gain insights."
                        linkText="I need this"
                        link="/sales/digital"
                        className="col-md-6 col-12"
                    />
                </div>

                {/* Software Soulution Dev Start */}
            </section>
            <section id="gereen-background">
                <div className="green-background-content ">
                    <p className="green-background-paragraph col-md-12">
                        We have the <span className="green-background-span">Right People</span> ,a <span className="green-background-span">Proven Process , </span>
                        and the
                    </p>
                    <div className="d-flex justify-content-center">
                        <p className="green-background-paragraph col-md-12 second-part">
                            <span className="green-background-span">Powerful Tools </span>
                            you need to build amazing web applications.
                        </p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="/sales/webiste" className="green-background-btn">SPEAK TO A GROWTH SPECIALIST</Link>
                    </div>
                </div>
            </section>

            {/* <section id="our-partners" className="col-12">
                <p className="our-partner-header">Our Partners</p>
                <p className="our-partner-paragraph">As experts in the field, we help corporates and individuals create a unique footprint in the digital world. We offer our clients the ability to: increase brand awareness and loyalty, gain insights.</p>
                <div className="d-none d-md-block">
                    <ol className="partner-logo d-flex justify-content-center">
                        <li><img src={ZainLogo} alt="zain logo" className="zain-logo" /></li>
                        <li><img src={OrangeLogo} alt="orange logo" className="orange-logo" /></li>
                        <li><img src={UmniahLogo} alt="umniah logo" className="umniah-logo" /></li>
                        <li><img src={ZainLogo} alt="zain logo" className="zain-logo" /></li>
                        <li><img src={UmniahLogo} alt="umniah logo" className="umniah-logo" /></li>
                        <li><img src={ZainLogo} alt="zain logo" className="zain-logo" /></li>

                    </ol>
                </div>
                <div className="d-block d-md-none d-flex justify-content-center mb-4">
                    <Carousel indicators={false} variant="dark" className="mobile-carousel">
                        <Carousel.Item >
                            <div className="company-logo">
                                <img
                                    className="d-block"
                                    src={OrangeLogo}
                                    alt="One"
                                />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item >
                            <div className="company-logo">
                                <img
                                    className="d-block"
                                    src={ZainLogo}
                                    alt=" Two"
                                />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item >
                            <div className="company-logo">
                                <img
                                    className="d-block"
                                    src={UmniahLogo}
                                    alt=" Two"
                                />
                            </div>

                        </Carousel.Item>
                    </Carousel>

                </div>
                <Link to="#" className="be-our-partner d-flex justify-content-center">Be a Pratner</Link>
            </section> */}
        </article>
    )
}