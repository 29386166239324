import React from "react";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import Home from "./screens/home";
import About from "./screens/about";
import Service from "./screens/services";
import MediaCenter from "./screens/media-center";
import Contact from "./screens/contact";
import Sales from "./screens/sales";

export default function Main() {
    return (
        <main>

            <Switch>
                <Route exact path="/">
                    <Redirect to="/home" />
                </Route>
                <Route path="/home" exact component={Home}></Route>
                <Route path="/about" exact component={About}></Route>
                <Route path="/services" exact component={Service}></Route>
                <Route path="/media-center" exact component={MediaCenter}></Route>
                <Route path="/sales/:target" exact component={Sales}></Route>
                <Route path="/contact" exact component={Contact}></Route>
            </Switch>

        </main>
    )
}